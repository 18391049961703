<template>
  <div>
    <div class="table-auto" v-if="items && items.length > 0">
      <b-table striped hover :items="items" :fields="fields" :id="tableId">
        <template #head(select)="data">
          <div style="display: flex">
            <b-form-checkbox
              size="lg"
              v-if="data.field.allSelect != null"
              v-model="data.field.allSelect"
              @change="checkboxAll($event)"
            ></b-form-checkbox>
            <!-- {{ data.field.label }} -->
          </div>
        </template>
        <template v-slot:cell(select)="row">
          <!-- <template v-if="row.item.select != 'disabled'"> -->
          <div title="">
            <b-form-checkbox
              size="lg"
              @input="checkboxChange($event, row.index, row.item)"
              v-model="row.item.select"
              :disabled="row.item.selectDisabled == 'disabled'"
            ></b-form-checkbox>
          </div>
        </template>

        <template v-slot:cell(image)="row">
          <div class="relativea">
            <img
              v-if="row.item.image"
              :src="row.item.image"
              alt=""
              width="100"
              class="pointer"
              @click="showImg(row.item.image)"
            />
            <img
              v-else
              alt="edit"
              width="100"
              src="@/assets/media/users/default.png"
            />
          </div>
        </template>

        <template v-slot:cell(article_information)="row">
          <!-- {{ row.item.article_information }} -->
          <div>
            <p>{{ row.item.article_information.brand }}</p>
            <p>{{ row.item.article_information.brand_article_number }}</p>
            <p>{{ row.item.article_information.product_name }}</p>
            <div style="display: flex">
              <div class="color_box mr-5" style="display: flex">
                Color:
                <!-- {{ row.item.article_information.color.indexOf('#') }} -->
                <div
                  v-if="
                    row.item.article_information.color &&
                      row.item.article_information.color.indexOf('#') >= 0
                  "
                  v-b-popover.hover.topleft="
                    row.item.article_information.color_name
                  "
                  :style="
                    `background-color:${row.item.article_information.color};width: 20px;height: 20px;margin-left: 5px;background-size: 100%;`
                  "
                ></div>
                <div
                  v-else
                  v-b-popover.hover.topleft="
                    row.item.article_information.color_name
                  "
                  :style="
                    `background:url(${row.item.article_information.color});width: 20px;height: 20px;margin-left: 5px;background-size: 100%;`
                  "
                ></div>
              </div>
              <div class="size_box" style="display: flex;white-space: nowrap;">
                <p>Size: {{ row.item.article_information.size }}</p>
              </div>
            </div>
            <p>{{ row.item.article_information.pos_article_no }}</p>
          </div>
        </template>

        <!-- Up-to-Date Total Purchase 颜色没写 -->
        <template v-slot:cell(action)="row">
          <ResetNow
            :item="row.item"
            v-if="row.item.action == 'Purchase Limits'"
          ></ResetNow>
          <b-icon
            v-if="
              row.item.action != null &&
                row.item.action.text == 'Export Settings' &&
                row.item.action.delete
            "
            icon="x-circle-fill"
            font-scale="1.5"
            variant="danger"
          ></b-icon>
          <router-link
            v-if="
              row.item.id &&
                (row.item.action == 'PurchaseTargetDetail' ||
                  row.item.action == 'WarehouseEntranceDetail')
            "
            :to="{
              name: 'purchase-overview-detail-index',
              params: {
                purchaseId: row.item.id
              }
            }"
            class="btn btn-primary btn-sm mr-2"
            >View</router-link
          >
          <!-- v-if="
              row.item.article_review_id &&
                row.item.action == 'WarehouseEntranceDetail' &&
                row.item.status == 'Received'
            " -->
          <router-link
            v-if="0"
            :to="{
              name: 'article-review-detail-index',
              params: {
                article_review_id: row.item.article_review_id
              }
            }"
            class="btn btn-primary btn-sm mr-2"
            >Review</router-link
          >

          <CreatePurchase
            v-if="
              tableId == 'purchase-target-overview-list' &&
                row.item.status != 'Voided' &&
                0
            "
            :purchaseTargetId="row.item.id"
            :maxQty="row.item.qty"
            :disabled="row.item.qty <= 0"
            :initFn="initDataFn"
            :size="'sm'"
            :qtyDisabled="true"
            class="mr-2 button"
          ></CreatePurchase>
        </template>
        <template v-slot:cell(status)="row">
          <div :class="'color_' + row.item.status.replace(/ /g, '_')">
            {{ row.item.status }}
          </div>
        </template>
        <template v-slot:cell(purchase_information)="row">
          <div>
            <p>{{ row.item.purchase_information.purchase_id }}</p>
            <p>{{ row.item.purchase_information.purchase_date }}</p>
            <p>{{ row.item.purchase_information.reserve_no }}</p>
          </div>
        </template>
        <template v-slot:cell(modification)="row">
          <p style="margin-bottom: 0;">
            {{ row.item.modification }}
          </p>
        </template>
        <template v-slot:cell(order_information)="row">
          <a :href="row.item.order_information.cps_order_url" target="_blank">{{
            row.item.order_information.cps_order_number
          }}</a>
          <p v-if="row.item.order_information.order_date">
            {{ row.item.order_information.order_date }}
          </p>
          <p>{{ row.item.order_information.position }}</p>
          <!-- {{ row.item.order_date ? 'YES' : 'NO' }} -->
        </template>

        <template v-slot:cell(reserved_qty)="row">
          <p
            v-if="
              row.item.reserved_qty == 0 ||
                row.item.action != 'PurchaseSummaryDetail'
            "
          >
            {{ row.item.reserved_qty }}
          </p>
          <TableSimple
            v-else
            :Text="row.item.reserved_qty"
            :Fields="table_simple_fields"
            :TableId="'reserved_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Reserved"
          ></TableSimple>
        </template>

        <template v-slot:cell(confirmed_qty)="row">
          <p
            v-if="
              row.item.confirmed_qty == 0 ||
                row.item.action != 'PurchaseSummaryDetail'
            "
          >
            {{ row.item.confirmed_qty }}
          </p>
          <TableSimple
            v-else
            :Text="row.item.confirmed_qty"
            :Fields="table_simple_fields"
            :TableId="'confirmed_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Confirmed"
          ></TableSimple>
        </template>

        <template v-slot:cell(delivered_qty)="row">
          <p
            v-if="
              row.item.delivered_qty == 0 ||
                row.item.action != 'PurchaseSummaryDetail'
            "
          >
            {{ row.item.delivered_qty }}
          </p>
          <TableSimple
            v-else
            :Text="row.item.delivered_qty"
            :Fields="table_simple_fields"
            :TableId="'delivered_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Delivered"
          ></TableSimple>
        </template>

        <template v-slot:cell(stored_qty)="row">
          <p
            v-if="
              row.item.stored_qty == 0 ||
                row.item.action != 'PurchaseSummaryDetail'
            "
          >
            {{ row.item.stored_qty }}
          </p>
          <TableSimple
            v-else
            :Text="row.item.stored_qty"
            :Fields="table_simple_fields"
            :TableId="'stored_qty'"
            :InitItems="initItems"
            :ProductId="row.item.product_id"
            QtyType="Stored"
          ></TableSimple>
        </template>
      </b-table>
      <b-modal
        v-model="imageModal"
        centered
        no-close-on-backdrop
        scrollable
        title="Image"
        hide-footer
        id="image-box"
        ref="modal"
        size="lg"
      >
        <img :src="showImgUrl" alt="" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import ResetNow from '@/views/content/Detail/ManagementPurchaseLimits/ResetNow.vue';
import CreatePurchase from '@/views/content/CreatePurchase/Index.vue';
import TableSimple from '@/components/Modal/TableSimple.vue';
export default {
  name: 'SimpleTable',
  components: { ResetNow, CreatePurchase, TableSimple },
  data() {
    return {
      imageModal: false,
      showImgUrl: '',
      popoverShow: true,
      sortDesc: true,
      limit: null,
      table_simple_fields: [
        {
          key: 'supplier',
          label: 'Supplier Name'
        },
        {
          key: 'qty',
          label: 'Quantity'
        }
      ]
    };
  },
  props: {
    items: {
      type: Array
    },
    fields: {
      type: Array
    },
    checkboxAll: {
      type: Function
    },
    checkboxChange: {
      type: Function
    },
    tableId: {
      type: String
    },
    initDataFn: {
      type: Function
    },
    options: {
      type: Object
    },
    searchObj: {
      type: Object
    }
  },
  created() {},
  methods: {
    showImg(url) {
      this.showImgUrl = url;
      this.imageModal = true;
    },
    initItems(data) {
      if (this.searchObj && this.searchObj != 'undefined') {
        data.order_date_from = this.searchObj.order_date_from;
        data.order_date_to = this.searchObj.order_date_to;
        data.sales_channel = this.searchObj.sales_channel;
        data.priority = this.searchObj.priority;
        data.order_type = this.searchObj.order_type;
      }
      return apiBus.purchase
        .purchaseSummarySupplierDetail(data)
        .then(data => {
          let item = [];
          data.data.data.supplier_qty_list.forEach(key => {
            item.push({
              product_id: data.data.data.product_id,
              supplier: this.outputLabel(
                this.options.supplier_list,
                key.supplier
              ),
              qty: key.qty
            });
          });
          return item;
        })
        .catch(error => {
          console.log(error);
          return [];
        });
    },
    outputLabel(data, val) {
      let label;
      data.forEach(item => {
        if (item.value == val) {
          label = item.label;
        }
      });
      return label;
    }
  },

  computed: {},
  mounted() {},
  watch: {}
};
</script>
<style scoped>
td p,
td a {
  margin-bottom: 2px;
  font-size: 14px;
  color: #333;
}
td a {
  color: #00a0e9;
}
.table-link-select {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
</style>
