<template>
  <div>
    <p class="imitation_link" @click="show">{{ Text }}</p>
    <b-modal
      v-model="modalShow"
      centered
      no-close-on-backdrop
      scrollable
      title="Details"
      hide-footer
      id="table-simple"
      ref="modal"
    >
      <b-overlay :show="loading" style="min-height: 150px">
        <div class="table-auto" v-if="items && items.length > 0">
          <b-table striped hover :items="items" :fields="Fields" :id="TableId">
          </b-table>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'TableSimple',
  components: {},
  data() {
    return {
      modalShow: false,
      loading: false,
      items: []
    };
  },
  props: ['Text', 'ProductId', 'Fields', 'TableId', 'InitItems', 'QtyType'],
  created() {},
  methods: {
    show() {
      this.modalShow = true;
      this.loading = true;
      this.InitItems({
        product_id: this.ProductId,
        qty_type: this.QtyType
      }).then(data => {
        this.loading = false;
        this.items = data;
      });
    }
  },
  computed: {},
  mounted() {},
  watch: {}
};
</script>
<style scoped></style>
