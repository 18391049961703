var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items && _vm.items.length > 0)?_c('div',{staticClass:"table-auto"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"id":_vm.tableId},scopedSlots:_vm._u([{key:"head(select)",fn:function(data){return [_c('div',{staticStyle:{"display":"flex"}},[(data.field.allSelect != null)?_c('b-form-checkbox',{attrs:{"size":"lg"},on:{"change":function($event){return _vm.checkboxAll($event)}},model:{value:(data.field.allSelect),callback:function ($$v) {_vm.$set(data.field, "allSelect", $$v)},expression:"data.field.allSelect"}}):_vm._e()],1)]}},{key:"cell(select)",fn:function(row){return [_c('div',{attrs:{"title":""}},[_c('b-form-checkbox',{attrs:{"size":"lg","disabled":row.item.selectDisabled == 'disabled'},on:{"input":function($event){return _vm.checkboxChange($event, row.index, row.item)}},model:{value:(row.item.select),callback:function ($$v) {_vm.$set(row.item, "select", $$v)},expression:"row.item.select"}})],1)]}},{key:"cell(image)",fn:function(row){return [_c('div',{staticClass:"relativea"},[(row.item.image)?_c('img',{staticClass:"pointer",attrs:{"src":row.item.image,"alt":"","width":"100"},on:{"click":function($event){return _vm.showImg(row.item.image)}}}):_c('img',{attrs:{"alt":"edit","width":"100","src":require("@/assets/media/users/default.png")}})])]}},{key:"cell(article_information)",fn:function(row){return [_c('div',[_c('p',[_vm._v(_vm._s(row.item.article_information.brand))]),_c('p',[_vm._v(_vm._s(row.item.article_information.brand_article_number))]),_c('p',[_vm._v(_vm._s(row.item.article_information.product_name))]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"color_box mr-5",staticStyle:{"display":"flex"}},[_vm._v(" Color: "),(
                  row.item.article_information.color &&
                    row.item.article_information.color.indexOf('#') >= 0
                )?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topleft",value:(
                  row.item.article_information.color_name
                ),expression:"\n                  row.item.article_information.color_name\n                ",modifiers:{"hover":true,"topleft":true}}],style:(("background-color:" + (row.item.article_information.color) + ";width: 20px;height: 20px;margin-left: 5px;background-size: 100%;"))}):_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topleft",value:(
                  row.item.article_information.color_name
                ),expression:"\n                  row.item.article_information.color_name\n                ",modifiers:{"hover":true,"topleft":true}}],style:(("background:url(" + (row.item.article_information.color) + ");width: 20px;height: 20px;margin-left: 5px;background-size: 100%;"))})]),_c('div',{staticClass:"size_box",staticStyle:{"display":"flex","white-space":"nowrap"}},[_c('p',[_vm._v("Size: "+_vm._s(row.item.article_information.size))])])]),_c('p',[_vm._v(_vm._s(row.item.article_information.pos_article_no))])])]}},{key:"cell(action)",fn:function(row){return [(row.item.action == 'Purchase Limits')?_c('ResetNow',{attrs:{"item":row.item}}):_vm._e(),(
            row.item.action != null &&
              row.item.action.text == 'Export Settings' &&
              row.item.action.delete
          )?_c('b-icon',{attrs:{"icon":"x-circle-fill","font-scale":"1.5","variant":"danger"}}):_vm._e(),(
            row.item.id &&
              (row.item.action == 'PurchaseTargetDetail' ||
                row.item.action == 'WarehouseEntranceDetail')
          )?_c('router-link',{staticClass:"btn btn-primary btn-sm mr-2",attrs:{"to":{
            name: 'purchase-overview-detail-index',
            params: {
              purchaseId: row.item.id
            }
          }}},[_vm._v("View")]):_vm._e(),(0)?_c('router-link',{staticClass:"btn btn-primary btn-sm mr-2",attrs:{"to":{
            name: 'article-review-detail-index',
            params: {
              article_review_id: row.item.article_review_id
            }
          }}},[_vm._v("Review")]):_vm._e(),(
            _vm.tableId == 'purchase-target-overview-list' &&
              row.item.status != 'Voided' &&
              0
          )?_c('CreatePurchase',{staticClass:"mr-2 button",attrs:{"purchaseTargetId":row.item.id,"maxQty":row.item.qty,"disabled":row.item.qty <= 0,"initFn":_vm.initDataFn,"size":'sm',"qtyDisabled":true}}):_vm._e()]}},{key:"cell(status)",fn:function(row){return [_c('div',{class:'color_' + row.item.status.replace(/ /g, '_')},[_vm._v(" "+_vm._s(row.item.status)+" ")])]}},{key:"cell(purchase_information)",fn:function(row){return [_c('div',[_c('p',[_vm._v(_vm._s(row.item.purchase_information.purchase_id))]),_c('p',[_vm._v(_vm._s(row.item.purchase_information.purchase_date))]),_c('p',[_vm._v(_vm._s(row.item.purchase_information.reserve_no))])])]}},{key:"cell(modification)",fn:function(row){return [_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v(" "+_vm._s(row.item.modification)+" ")])]}},{key:"cell(order_information)",fn:function(row){return [_c('a',{attrs:{"href":row.item.order_information.cps_order_url,"target":"_blank"}},[_vm._v(_vm._s(row.item.order_information.cps_order_number))]),(row.item.order_information.order_date)?_c('p',[_vm._v(" "+_vm._s(row.item.order_information.order_date)+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(row.item.order_information.position))])]}},{key:"cell(reserved_qty)",fn:function(row){return [(
            row.item.reserved_qty == 0 ||
              row.item.action != 'PurchaseSummaryDetail'
          )?_c('p',[_vm._v(" "+_vm._s(row.item.reserved_qty)+" ")]):_c('TableSimple',{attrs:{"Text":row.item.reserved_qty,"Fields":_vm.table_simple_fields,"TableId":'reserved_qty',"InitItems":_vm.initItems,"ProductId":row.item.product_id,"QtyType":"Reserved"}})]}},{key:"cell(confirmed_qty)",fn:function(row){return [(
            row.item.confirmed_qty == 0 ||
              row.item.action != 'PurchaseSummaryDetail'
          )?_c('p',[_vm._v(" "+_vm._s(row.item.confirmed_qty)+" ")]):_c('TableSimple',{attrs:{"Text":row.item.confirmed_qty,"Fields":_vm.table_simple_fields,"TableId":'confirmed_qty',"InitItems":_vm.initItems,"ProductId":row.item.product_id,"QtyType":"Confirmed"}})]}},{key:"cell(delivered_qty)",fn:function(row){return [(
            row.item.delivered_qty == 0 ||
              row.item.action != 'PurchaseSummaryDetail'
          )?_c('p',[_vm._v(" "+_vm._s(row.item.delivered_qty)+" ")]):_c('TableSimple',{attrs:{"Text":row.item.delivered_qty,"Fields":_vm.table_simple_fields,"TableId":'delivered_qty',"InitItems":_vm.initItems,"ProductId":row.item.product_id,"QtyType":"Delivered"}})]}},{key:"cell(stored_qty)",fn:function(row){return [(
            row.item.stored_qty == 0 ||
              row.item.action != 'PurchaseSummaryDetail'
          )?_c('p',[_vm._v(" "+_vm._s(row.item.stored_qty)+" ")]):_c('TableSimple',{attrs:{"Text":row.item.stored_qty,"Fields":_vm.table_simple_fields,"TableId":'stored_qty',"InitItems":_vm.initItems,"ProductId":row.item.product_id,"QtyType":"Stored"}})]}}],null,false,1414074684)}),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","scrollable":"","title":"Image","hide-footer":"","id":"image-box","size":"lg"},model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[_c('img',{attrs:{"src":_vm.showImgUrl,"alt":""}})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }