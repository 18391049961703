<template>
  <div style="display: inline-block">
    <b-button :size="size" variant="primary" class="mr-2" @click="onShowModal()"
      >Logs</b-button
    >
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="History"
      id="edit-invoice-information"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="modalloading">
        <SimpleTable
          :items="items"
          :fields="fields"
          :tableId="'product_info_detail'"
          style="margin-top: 20px"
        ></SimpleTable>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SimpleTable from '@/components/Table/Simple.vue';
// import SelectLoading from '@/components/Loading/Index';
export default {
  name: 'HistoryInvoiceInformation',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ]
    };
  },
  props: ['id', 'size'],

  components: { SimpleTable },
  methods: {
    onShowModal() {
      this.showModal = true;
      this.modalloading = true;
      apiBus.purchaseInvoice
        .getPurchaseInvoiceLog({
          purchase_invoice_id: this.id
        })
        .then(data => {
          this.modalloading = false;
          this.items = data.data.data;
          console.log(this.items);
        })
        .catch(error => {
          console.log(error);
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },

    init() {}
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
.confirm-delivery-modal {
  height: 300px;
}
</style>
