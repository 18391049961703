<template>
  <div>
    <b-button variant="primary" size="sm" @click="clickModal"
      >Reset Now</b-button
    >

    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Reset Total Purchase Value"
      id="reset"
      ref="modal"
    >
      <b-overlay :show="loading">
        <p>Are you sure to reset the up-to-date total purchase value?</p>
        <p>{{ item.brand }} {{ item.category }}</p>
        <p>
          After reset, the up-to-date total purchase will be set back to 0,00 €.
          The up-to-date total purchase will be reset automatically on the first
          day of every month.
        </p>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          size="sm"
          variant="primary"
          @click="yes()"
          :disabled="disabled"
          >YES</b-button
        >
        <b-button size="sm" variant="secondary" @click="close()">NO</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'ResetNow',
  components: {},
  data() {
    return {
      showModal: false,
      loading: false,
      disabled: false
    };
  },
  props: ['item'],
  methods: {
    clickModal() {
      this.loading = false;
      this.disabled = false;
      this.showModal = true;
    },
    yes() {
      this.$store.dispatch(PROMPT_STATUS, {
        dismissCountDown: 5, //初始提示作用于定时器
        alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
        alertText: '没有接口' // 提示内容
      });
      this.loading = true;
      this.disabled = true;
    },
    init() {
      // console.log(this.$route);
    }
  },

  computed: {
    // ...mapState({
    //   baseConfig: (state) => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
